<template>
  <el-form @submit.prevent>
    <div class="login_zone">
      <BackButton class="mb-3" text="返回" url="/login"></BackButton>
      <div class="other_login">
        <div class="icon"><img class="otherBtn" @click="lineLogin()" :src="require('/src/assets/image/Shared/Login/Single_Line.png')" /></div>
        <div class="icon"><img class="otherBtn" @click="loginWithGoogle()" :src="require('/src/assets/image/Shared/Login/Single_Google.png')" /></div>
      </div>
      <el-button class="mt-3" data-bs-toggle="collapse" data-bs-target="#showOtherRegister">
        <span>其他註冊方式</span>
      </el-button>
      <div class="collapse" id="showOtherRegister">
        <div class="input_form">
          <div class="login_account">
            <el-form-item prop="account" label="帳號" class="mb-0 pb-2">
              <el-input type="email" class="inner-input" ref="autoFocus" v-model="account" autocomplete="on" placeholder="請輸入欲註冊的Email" />
            </el-form-item>
          </div>
          <div class="d-flex align-items-center mb-3">
            <el-input type="text" v-model="verifyCodeInput" autocomplete="on" class="inner-input" @keydown.enter.prevent="login" :disabled="!isVerifying" placeholder="請輸入驗證碼" />
            <el-button class="ms-1" plain @click="verifyMember()" :disabled="isVerifying">
              <span>發送驗證碼</span>
              <span v-if="isVerifying">({{ verifyTime }})</span>
            </el-button>
          </div>
          <ConfirmButton class="login_btn" @click="verifyCode()" text="驗證"></ConfirmButton>
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import ConfirmButton from "../../Common/CommonElement/ConfirmButton.vue";
import BackButton from "../../Common/CommonElement/BackButton.vue";
import Storage from "../../../models/storage";
import { googleSdkLoaded } from "vue3-google-login";

export default {
  name: "VerifyEmailComponent",
  components: {
    ConfirmButton,
    BackButton,
  },
  data() {
    return {
      account: "",
      verifyCodeInput: "",
      verifyTime: 300,
      isVerifying: false,
    };
  },
  mounted() {
    this.$nextTick(() => this.$refs.autoFocus.focus());
  },
  methods: {
    loginWithGoogle() {
      googleSdkLoaded(google => {
        google.accounts.oauth2
          .initCodeClient({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            scope: "email profile openid",
            redirect_uri: `${process.env.VUE_APP_CURRENT_DOMAIN}`,
            callback: response => {
              if (response.code) {
                this.googleAuthorize(response.code);
              }
            },
          })
          .requestCode();
      });
    },
    async googleAuthorize(code) {
      const vm = this;

      vm.$store.state.isloading = true;
      if (code == "" || code == null) {
        await vm.$router.push("/login");
      }

      let user = vm.$store.state.auth.user;
      let userId = null;
      if (user != null) userId = user.id;

      let par = { AuthCode: code, UserId: userId };

      await vm.$store
        .dispatch("auth/googleLogin", par)
        .then(() => {
          let name = vm.$store.state.auth.user.name;
          let message = name === "" ? "登入成功" : `${name}，歡迎回來！`;
          vm.notify("success", message);
          vm.$router.push(Storage.get("ChargeMe_Setting").page);
        })
        .catch(() => {
          Storage.remove("user");
          vm.notify("error", "登入失敗！");
        })
        .finally(() => {
          vm.$store.state.isloading = false;
        });
    },
    lineLogin() {
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.VUE_APP_LINE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_CURRENT_DOMAIN}LineAuth&state=12345abcde&scope=profile%20openid`;
    },
    isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailRegex.test(email);
    },
    async checkUserByEmail() {
      const vm = this;
      try {
        const res = await vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Account/CheckEmail/${vm.account}`);
        if (res.data.registerType !== "") {
          vm.notify("error", `此信箱已被註冊`);
          return false;
        }
        return true;
      } catch (err) {
        return false;
      }
    },
    async verifyMember() {
      const vm = this;
      if (!vm.isValidEmail(vm.account)) {
        vm.notify("error", `請輸入正確Email格式`);
        return false;
      }
      var isValid = await vm.checkUserByEmail(); //檢查是否有被註冊
      if (!isValid) return;

      const data = {
        Email: vm.account,
      };
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/verificationByEmail`, data)
        .then(() => {
          vm.notify("success", "驗證信發送成功！");
          vm.isVerifying = true;

          setInterval(() => {
            vm.verifyTime -= 1;
            if (vm.verifyTime == 0) {
              vm.verifyTime = 300;
              vm.isVerifying = false;
              return;
            }
          }, 1000);
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", "驗證信發送失敗！");
        });
    },
    async verifyCode() {
      const vm = this;
      const data = {
        Email: vm.account,
        VerifyCode: vm.verifyCodeInput.trim(),
      };
      if (data.VerifyCode == "") {
        vm.notify("error", `請輸入驗證碼`);
        return;
      }
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/VerifyCode`, data).then(res => {
        if (res.data.verifySuccess) {
          this.$router.push({
            name: "Shared_Register",
            query: { email: this.account },
          });
        } else {
          vm.notify("error", `驗證碼輸入錯誤`);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__wrapper {
  box-shadow: none;
  border: none;
  background-color: #ffffff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 4px 8px;
  border-radius: 4px;
  width: 100%;
  border-radius: 1rem;
}
.login_zone {
  height: 100%;
  padding-top: 0;
  .el-form-item {
    display: block;
    text-align: start;
    margin-bottom: 1rem;
  }
  .login_btn {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    color: white;
    width: 100%;
    padding: 0 !important;
  }
  .login_password {
    margin: 0;
  }
  .other_login {
    display: flex;
    // justify-content: space-around;
    justify-content: center;
    .icon {
      margin: 0.5rem 1.5rem;
    }
  }
}
.icon {
  border-radius: 50%;
  box-shadow: 1px 1px 3px 1px #b0b0b0;
  overflow: hidden;
  width: 4rem;
  height: 4rem;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 3px 1px #565656;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
